<!--
  处于 tabs 中的内容无法自动填充剩下的区域，可用此组件 通过js计算 处于 tabs 中剩余的高度并填充
  - 依赖库：lodash
  - 安装：npm install lodash --save
  - https://www.lodashjs.com/docs/lodash.throttle
-->
<template>
  <div class="table-height-full flex flex-dc" ref="autoFulRef" :style="{height: `${surplusHeight}px`}">
    <div class="flex-1">
      <!-- 表格组件 -->
      <slot name="table"></slot>
    </div>
		<slot name="payTotal"></slot>
    <!-- 表格组件下方内容 -->
    <slot name="after"></slot>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'TableHeightFull',
  props: {
    bottomHeight: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      // 剩余高度
      surplusHeight: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      // 计算高度
      this.calcHeight()
      // 注册监听窗口变化
      this.addWindowChange()
    })
  },
  methods: {
    // 计算高度
    calcHeight () {
      if (this.bottomHeight != 0) {
        console.log(this.bottomHeight);
      }
      this.surplusHeight = Math.floor(window.innerHeight - this.$refs.autoFulRef.getBoundingClientRect().top - this.bottomHeight) - 1
    },
    // 注册监听窗口变化
    addWindowChange () {
      window.addEventListener('resize', this.windowResize, true)
    },
    // 节流函数 - 触发计算高度函数
    windowResize: _.throttle(function () {
      this.calcHeight()
    }, 500),
    // 移除监听窗口变化
    removeWindowChange () {
      window.removeEventListener('resize', this.windowResize, true)
    }
  },
  beforeDestroy () {
    // 移除监听窗口变化
    this.removeWindowChange()
  }
}
</script>

<style scoped lang="less">
.table-height-full {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.flex-dir-col {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
  height: 0;
}
</style>
